import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TechnologyStack = () => {
  return (
    <div className="mt-12 md:mt-24">
      <h2 className="text-3xl md:text-4xl text-center font-serif">
        Technology Stack
      </h2>

      <div className="mt-16 mb-12 px-8 md:px-48 lg:px-8 flex flex-col lg:flex-row lg:gap-x-8 lg:justify-center">
        <div className="self-start h-64 w-64 bg-[#FB3640] text-white rounded-3xl text-center shadow-xl mt-8 flex flex-col justify-evenly">
          <div>
            <h4 className="text-3xl font-serif">
              Front End
            </h4>
          </div>

          <div>
            <p className="font-light text-lg">
              Web, mobile, desktop |
              <br />
              on-premise
            </p>
          </div>
        </div>

        <div className="self-end h-64 w-64 bg-[#FFB000] text-white rounded-3xl text-center shadow-xl mt-8 flex flex-col justify-evenly">
          <div>
            <h4 className="text-3xl font-serif">
              Application Layer
            </h4>
          </div>

          <div>
            <p className="font-light text-lg">
              Python (Flask | FastAPI)
              <br />
              Javascript (React)
            </p>
          </div>
        </div>

        <div className="self-start h-64 w-64 bg-[#5B23A4] text-white rounded-3xl text-center shadow-xl mt-8 flex flex-col justify-evenly">
          <div>
            <h4 className="text-3xl font-serif">
              ML / Data
              <br />
              Analysis
            </h4>
          </div>

          <div>
            <p className="font-light text-lg">
              Tensorflow, Keras,
              <br />
              PyTorch, BigQuery
            </p>
          </div>
        </div>

        <div className="self-end h-64 w-64 bg-[#35ACCA] text-white rounded-3xl text-center shadow-xl mt-8 flex flex-col justify-evenly">
          <div>
            <h4 className="text-3xl font-serif">
              Databases
            </h4>
          </div>

          <div>
            <p className="font-light text-lg">
              SQL, NoSQL, Firebase
            </p>
          </div>
        </div>

        <div className="self-start h-64 w-64 bg-[#413D45] text-white rounded-3xl text-center shadow-xl mt-8 flex flex-col justify-evenly">
          <div>
            <h4 className="text-3xl font-serif">
              Cloud
              <br />
              Providers
            </h4>
          </div>

          <div>
            <p className="font-light text-lg">
              GCP, AWS, Heroku,
              <br />
              Gatsby
            </p>
          </div>
        </div>
      </div>

      <div className="md:flex md:flex-col md:justify-center md:px-24 lg:px-32 2xl:px-48">
        <div className="p-12 md:flex md:items-center md:gap-x-12">
          <div className="my-8 md:flex-1">
            <h2 className="text-4xl font-serif">
              Level-up to a cloud based environment
            </h2>

            <p className="mt-8 font-light text-lg leading-7">
              Hillside Lab's skilled engineers design customized cloud solutions allowing your company to operate efficiently, providing the return on investment you have been looking for. Don't worry about cloud migration challenges, a cloud migration strategy will be put in place before moving data center capabilities into the the cloud. We can deliver modern architecture within popular clouds such as AWS, GCP, and Azure.
            </p>
          </div>
          <div className="my-8 md:flex-1">
            <StaticImage
              className=""
              src="../images/cloud-based.png"
              alt="Level-up to a cloud based environment"
            />
          </div>
        </div>

        <div className="p-12 md:flex md:items-center md:gap-x-12">
          <div className="my-8 md:flex-1 md:order-last">
            <h2 className="text-4xl font-serif">
              The Internet of Things (IoT) is transforming the way companies do business
            </h2>

            <p className="mt-8 font-light text-lg leading-7">
              Advantages of IoT include improved customer service and retention, efficient and cost effective operations, and more. As a full-stack IoT company, Hillside Lab will:
            </p>

            <ul className="mt-6 font-light text-lg leading-7 list-disc">
              <li className="mt-2 ml-6">
                Build smart gadgets
              </li>
              <li className="mt-2 ml-6">
                Bring analog products to the digital world using fully customized IoT solutions
              </li>
              <li className="mt-2 ml-6">
                Address both hardware and software development; including circuit design, PCB layout, firmware, interface development, and more
              </li>
            </ul>

            <p className="mt-6 font-light text-lg leading-7">
              Hillside Lab will create the IoT infrastructure that will give your company a true advantage.
            </p>
          </div>
          <div className="my-8 md:flex-1 md:order-first">
            <StaticImage
              className=""
              src="../images/iot.png"
              alt="IoT"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyStack;
