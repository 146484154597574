import React from 'react';

import Layout from '../components/Layout';
import CustomSolutionsSplash from '../components/CustomSolutionsSplash';
import ProductDevelopmentCycle from '../components/ProductDevelopmentCycle';
import TechnologyStack from '../components/TechnologyStack';
import SEO from '../components/SEO';

const CustomSolutions = () => {
  return (
    <Layout>
      <SEO title="Custom Solutions" />
      <CustomSolutionsSplash />
      <ProductDevelopmentCycle />
      <TechnologyStack />
    </Layout>
  );
};

export default CustomSolutions;
