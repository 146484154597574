import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const CustomSolutionsSplash = () => {
  return (
    <>
      {/* Mobile splash */}
      <div className="md:hidden">
        <div className="bg-[#F0FDFC]">
          <div className="px-8 pt-6 pb-4">
            <h1 className="text-2xl tracking-wider font-medium font-serif text-center">
              Analog process meets<br />digial efficiency
            </h1>

            <p className="mt-16 text-center font-light">
              Our team of engineers and designers are ready to solve your company's challenges and create custom solutions to help your business thrive.
            </p>

            <StaticImage
              className="mt-8"
              src="../images/custom-solutions-hero-mobile.png"
              alt="Custom solutions to help your business thrive"
            />
          </div>
        </div>
      </div>

      {/* Desktop splash */}
      <div className="hidden md:block">
        <div className="bg-[#F0FDFC]">
          <div className="px-8 pt-24 pb-4">
            <h1 className="text-5xl xl:text-6xl tracking-wider font-medium font-serif text-center">
              Analog process meets digial efficiency
            </h1>

            <p className="mt-12 md:px-32 lg:px-64 xl:px-96 text-center font-light text-lg">
              Our team of engineers and designers are ready to solve your company's challenges and create custom solutions to help your business thrive.
            </p>

            <div className="xl:max-w-6xl 2xl:max-w-7xl mx-auto">
              <StaticImage
                className="mt-8"
                src="../images/custom-solutions-hero-desktop.png"
                alt="Custom solutions to help your business thrive"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomSolutionsSplash;
