import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ProductDevelopmentCycle = () => {
  return (
    <div className="">
      <Carousel dynamicHeight={true}>
        <div className="bg-[#270356]">
          <StaticImage
            className=""
            src="../images/product-development-cycle-learn.png"
            alt="Production development cycle step 1: learn"
          />
        </div>
        <div className="bg-[#270356]">
          <StaticImage
            className=""
            src="../images/product-development-cycle-design.png"
            alt="Production development cycle step 2: design"
          />
        </div>
        <div className="bg-[#270356]">
          <StaticImage
            className=""
            src="../images/product-development-cycle-build.png"
            alt="Production development cycle step 3: build"
          />
        </div>
        <div className="bg-[#270356]">
          <StaticImage
            className=""
            src="../images/product-development-cycle-test.png"
            alt="Production development cycle step 4: test"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default ProductDevelopmentCycle;
